import { PagerModule } from '@progress/kendo-angular-pager';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ComponentsModule } from '../../shared/components/components.module';
import { RpReportsComponent } from './rp-reports.component';
import { ReportsRoutingModule } from './rp-reports-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule({
  declarations: [RpReportsComponent],
  imports: [
    SharedModule,
    CommonModule,
    NgSelectModule,
    DropDownsModule,
    ReportsRoutingModule,
    PagerModule,
    UploadsModule,
    LabelModule,
    ComponentsModule,
    BsDatepickerModule,
    AccordionModule.forRoot(),
  ],
})
export class ReportsModule { }
